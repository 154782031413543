<template>
  <div>
    <Combobox @update:model-value="onSelect">
      <div class="relative z-40">
        <ComboboxInput
          class="sm:text-md block w-full rounded-full border-neutral-300 px-4 shadow-sm focus:border-black focus:ring-black dark:border-neutral-600 dark:bg-neutral-800 dark:text-white"
          :placeholder="$t('header.search')"
          @change="searchQuery = $event.target.value"
        />
      </div>
      <TransitionRoot :show="show" as="template" appear>
        <Dialog as="div" class="relative z-30" @close="reset">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-neutral-500/70 transition-opacity dark:bg-neutral-400/70" />
          </TransitionChild>

          <div class="fixed inset-0 z-40 mx-auto max-w-screen-2xl overflow-y-auto px-4 py-20 sm:px-8 lg:px-16">
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="ring-opacity-5 ml-auto mr-auto transform divide-y divide-neutral-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black transition-all dark:divide-neutral-800 dark:bg-black lg:ml-[230px] lg:mr-[340px]"
              >
                <ComboboxOptions
                  v-if="categories.length > 0"
                  static
                  class="max-h-[80vh] scroll-py-3 overflow-y-auto p-3"
                >
                  <li v-for="category in categories" :key="category">
                    <h2 class="text-md py-2.5 font-semibold text-neutral-900 dark:text-neutral-50">
                      {{ category }}
                    </h2>
                    <ul class="mt-2 text-sm text-neutral-800 dark:text-neutral-100">
                      <ComboboxOption
                        v-for="item in searchResults[category]"
                        :key="item.id"
                        v-slot="{ active }"
                        :value="item"
                        as="template"
                      >
                        <NuxtLink
                          as="li"
                          :to="getUrl(category, item)"
                          :class="[
                            'flex cursor-pointer select-none rounded-xl p-3',
                            active && 'bg-neutral-100 dark:bg-neutral-800',
                          ]"
                          @click="reset()"
                        >
                          <div :class="['flex h-12 w-12 flex-none items-center justify-center rounded-lg']">
                            <img
                              v-if="getAvatar(category, item)"
                              :src="getAvatar(category, item)"
                              :class="category === 'profiles' ? 'rounded-full' : 'rounded-md'"
                              class="h-12 w-12 object-cover"
                              aria-hidden="true"
                            />
                          </div>
                          <div class="ml-4 flex-auto overflow-hidden">
                            <p
                              :class="[
                                'truncate text-sm font-medium',
                                active
                                  ? 'text-neutral-900 dark:text-neutral-50'
                                  : 'text-neutral-700 dark:text-neutral-200',
                              ]"
                            >
                              {{ getName(category, item) }}
                            </p>
                            <p
                              :class="[
                                'truncate text-sm',
                                active
                                  ? 'text-neutral-700 dark:text-neutral-200'
                                  : 'text-neutral-500 dark:text-neutral-400',
                              ]"
                            >
                              {{ getDescription(category, item) }}
                            </p>
                          </div>
                        </NuxtLink>
                      </ComboboxOption>
                    </ul>
                  </li>
                </ComboboxOptions>

                <ComboboxOptions
                  v-else-if="searchQuery !== '' && searchInProgress"
                  static
                  class="max-h-[80vh] scroll-py-3 overflow-y-auto p-3"
                >
                  <li>
                    <div class="text-md py-2.5 font-semibold text-neutral-900 dark:text-neutral-50">
                      <div class="h-6 w-32 animate-pulse rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
                    </div>
                    <ul class="mt-2 text-sm text-neutral-800 dark:text-neutral-100">
                      <ComboboxOption v-for="index in 3" :key="index" as="template">
                        <li :class="['flex cursor-pointer select-none rounded-xl p-3']">
                          <div :class="['flex h-12 w-12 flex-none items-center justify-center rounded-lg']">
                            <div class="h-12 w-12 animate-pulse rounded-md bg-neutral-100 dark:bg-neutral-800"></div>
                          </div>
                          <div class="ml-4 flex-auto overflow-hidden">
                            <div class="h-6 w-3/4 animate-pulse rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
                            <div class="h-6 w-3/4 animate-pulse rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
                          </div>
                        </li>
                      </ComboboxOption>
                    </ul>
                  </li>
                </ComboboxOptions>

                <div v-else class="px-6 py-14 text-center text-sm sm:px-14">
                  <ExclamationCircleIcon class="mx-auto h-6 w-6 text-neutral-400 dark:text-neutral-500" />
                  <p class="mt-4 font-semibold text-neutral-900 dark:text-neutral-50">{{ $t('header.noResults') }}</p>
                  <p class="mt-2 text-neutral-500 dark:text-neutral-400">
                    {{ $t('header.nothingFound') }}
                  </p>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
    </Combobox>
  </div>
</template>

<script setup lang="ts">
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { ExclamationCircleIcon } from '@heroicons/vue/outline';
import { watchDebounced } from '@vueuse/core';
import { ref } from 'vue';
import { useRouter } from '#imports';
import { getApiRoot } from '~/utilities/api';
import { getUrlForCollection, getUrlForNft, getUrlForProfile } from '~/utilities/url-slugs';

const router = useRouter();

const show = ref(false);
const searchQuery = ref('');
const categories = ref([]);
const searchInProgress = ref(false);
const searchResults = ref(undefined);
const searchError = ref(undefined);

const emit = defineEmits(['close']);

const onSelect = (item) => {
  let category = 'profiles';
  if (item.encoded_id?.startsWith('nft1')) {
    category = 'nfts';
  } else if (item.encoded_id?.startsWith('xch1')) {
    category = 'addresses';
  } else if (item.id?.startsWith('col1')) {
    category = 'collections';
  }
  router.push(getUrl(category, item));
  reset();
};

watchDebounced(
  searchQuery,
  (searchQuery) => {
    if (searchQuery) {
      show.value = true;
      search();
    } else {
      show.value = false;
      categories.value = [];
      searchResults.value = undefined;
    }
  },
  { debounce: 500 }
);

const reset = () => {
  emit('close');
  categories.value = [];
  searchResults.value = undefined;
  searchError.value = undefined;
  show.value = false;
};

const search = async () => {
  searchInProgress.value = true;
  try {
    const data = await $fetch(`${getApiRoot()}/search`, {
      params: {
        query: searchQuery.value.trim(),
      },
    });
    if (data) {
      searchResults.value = data;
      const resultKeys = Object.entries(data)
        .filter(([_, value]) => value.length > 0)
        .map(([key]) => key);
      categories.value = ['profiles', 'collections', 'nfts', 'addresses'].filter((key) => resultKeys.includes(key));
      show.value = true;
    } else {
      categories.value = [];
      searchResults.value = undefined;
    }
  } catch (e) {
    console.log(e);
    searchError.value = e;
    show.value = true;
  } finally {
    searchInProgress.value = false;
  }
};

const getAvatar = (category, item) => {
  switch (category) {
    case 'collections':
      return item.thumbnail_uri;
    case 'nfts':
      return item.thumbnail_uri;
    case 'profiles':
      return item.avatar_uri;
  }
};
const getName = (category, item) => {
  switch (category) {
    case 'collections':
      return item.name;
    case 'nfts':
      return item.name || 'Unnamed';
    case 'profiles':
      return item.name || 'Unnamed';
    case 'addresses':
      return item.encoded_id;
  }
};
const getDescription = (category, item) => {
  switch (category) {
    case 'collections':
      return item.description;
    case 'nfts':
      return item.description;
    case 'profiles':
      return item.encoded_id;
  }
};
const getUrl = (category, item) => {
  switch (category) {
    case 'collections':
      return getUrlForCollection(item);
    case 'nfts':
      return getUrlForNft(item);
    case 'profiles':
      return getUrlForProfile(item);
    case 'addresses':
      return `/address/${item.encoded_id}`;
  }
};
</script>
