<script setup lang="ts">
import Navbar from '~/components/Navbar.vue';
import Footer from '~/components/Footer.vue';

const route = useRoute();
const config = useRuntimeConfig();
</script>

<template>
  <div
    :class="[
      'relative flex min-h-screen flex-col transition duration-100 ease-in-out',
      route.meta?.backgroundClass || 'bg-white dark:bg-black',
    ]"
  >
    <Navbar />
    <div class="flex w-full flex-grow flex-col" :class="route.meta?.noPadding ? '' : 'pt-16'">
      <slot />
    </div>
    <Footer />
    <div v-if="config.public.network !== 'mainnet'" class="absolute left-0 top-0 h-16 w-16">
      <div
        class="absolute left-[-24px] top-[16px] z-50 w-[100px] -rotate-45 transform bg-amber-600 py-1 text-center text-[0.5rem] font-semibold text-white dark:text-black lg:left-[-34px] lg:top-[32px] lg:w-[170px] lg:text-base"
      >
        Testnet
      </div>
    </div>
  </div>
</template>
