<script setup>
import { ref } from 'vue';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { UserIcon } from '@heroicons/vue/outline';
import { FingerPrintIcon } from '@heroicons/vue/solid';
import { Hub } from 'aws-amplify/utils';
import { useRouter } from '#imports';
import { avatarGradientStyles } from '~/utilities/avatar-gradient';
import { getApiRoot } from '~/utilities/api';

const route = useRoute();
const router = useRouter();
const { $axios, $Amplify } = useNuxtApp();

const gobyStore = useGobyStore();

// useState nur relevant, wenn cross component, sonst ref
const currUserAuth = ref(undefined);
const userProfiles = useState('userProfiles', () => []);
const activeProfile = ref();

async function fetchUser() {
  try {
    if (process.server) {
      currUserAuth.value = await $Amplify.Auth.getCurrentUser();
    } else {
      currUserAuth.value = await $Amplify.Auth.getCurrentUser();
      // awaiting this call here somehow breaks reactivity for any element that accesses `activeProfile`
      $axios.get(`${getApiRoot()}/user/profiles`).then(({ data: profiles }) => {
        userProfiles.value = profiles;
        activeProfile.value = profiles[0];
      });
    }
  } catch (error) {
    currUserAuth.value = null;
  }
}

await fetchUser();

const isLoggedInLegacy = computed(() => {
  return currUserAuth.value !== null;
});
const isLoggedInGoby = computed(() => {
  return gobyStore.account;
});
const isLoggedIn = computed(() => {
  return isLoggedInLegacy.value || isLoggedInGoby.value;
});

if (process.client) {
  const stopListening = Hub.listen('auth', ({ payload }) => {
    const { event } = payload;
    if (event === 'signIn') {
      fetchUser();
    }
  });
  onBeforeUnmount(() => stopListening());
}

const logoutError = ref('');

async function signOut() {
  try {
    logoutError.value = '';
    await $Amplify.Auth.signOut();
    currUserAuth.value = null;
    activeProfile.value = undefined;
    await router.push('/');
    return true;
  } catch (err) {
    console.log('error signing out: ', err);
    logoutError.value = err.message;
    return false;
  }
}

function disconnect() {
  gobyStore.disconnect();
}

const showConnectWalletModal = useState('showConnectWalletModal', () => false);
</script>

<template>
  <div>
    <div class="max-w-sm">
      <div v-if="!isLoggedIn">
        <button
          type="button"
          class="text-md betterhover:hover:shadow-card-hover hidden justify-center whitespace-nowrap rounded-full border border-transparent bg-emerald-600 px-4 py-2 font-semibold text-white transition duration-100 ease-in-out dark:text-white lg:flex"
          @click="showConnectWalletModal = true"
        >
          Sign In
        </button>
      </div>

      <Popover v-else-if="isLoggedInGoby" v-slot="{ open }" class="relative flex">
        <PopoverButton
          :class="route.meta?.whiteNavbar ? 'bg-white dark:bg-black' : 'bg-emerald-600 dark:bg-emerald-600'"
          class="text-md betterhover:hover:shadow-card-hover flex flex-shrink-0 justify-center whitespace-nowrap rounded-full border border-transparent px-2 py-2 font-medium text-white transition duration-100 ease-in-out dark:text-white sm:px-4"
        >
          <img
            :class="['h-6 w-6 sm:-ml-1 sm:mr-2', route.meta?.whiteNavbar ? 'hidden dark:block' : 'dark:block']"
            aria-hidden="true"
            src="/goby-logo-white.svg"
          />
          <img
            :class="['h-6 w-6 sm:-ml-1 sm:mr-2', route.meta?.whiteNavbar ? 'dark:hidden' : 'hidden']"
            aria-hidden="true"
            src="/goby-logo.svg"
          />
          <span
            :class="route.meta?.whiteNavbar ? 'text-black dark:text-white' : 'text-white dark:text-white'"
            class="my-auto hidden font-mono text-sm font-semibold md:block"
            >{{ gobyStore.account.substring(0, 8) }}</span
          >
        </PopoverButton>

        <PopoverPanel
          v-slot="{ close }"
          class="absolute right-0 top-12 z-50 mt-3 w-max max-w-sm rounded-lg sm:px-0 lg:max-w-3xl"
        >
          <div class="z-5 w-48 rounded-lg shadow-lg ring-1 ring-black/5 dark:ring-white/20">
            <div class="relative z-50 rounded-lg bg-white p-4 dark:bg-black">
              <div class="grid grid-cols-1 gap-4" @click="close()">
                <NuxtLink
                  to="/wallet"
                  active-class="text-black dark:text-white"
                  class="font-medium text-neutral-700 hover:text-black dark:text-neutral-200"
                  >My Wallet
                </NuxtLink>

                <div
                  class="font-medium text-neutral-700 hover:cursor-pointer hover:text-black dark:text-neutral-200"
                  @click="disconnect()"
                >
                  Disconnect
                </div>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </Popover>

      <Popover v-else-if="isLoggedInLegacy" v-slot="{ open }" class="relative">
        <PopoverButton
          :class="open ? '' : 'text-opacity-90'"
          class="group static inline-flex items-center justify-center text-base font-medium text-neutral-700 transition duration-150 hover:opacity-80 focus:outline-none dark:text-neutral-200"
        >
          <div
            v-if="!activeProfile"
            class="flex h-12 w-12 items-center justify-center rounded-full bg-neutral-200 dark:bg-neutral-700"
          >
            <UserIcon class="h-6 w-6 text-neutral-400 dark:text-neutral-500" />
          </div>
          <div
            v-else
            :class="[
              `h-12 w-12 rounded-full`,
              !activeProfile.avatar_uri
                ? 'flex items-center justify-center bg-gradient-to-br'
                : 'bg-neutral-200 dark:bg-neutral-700',
            ]"
            :style="avatarGradientStyles(activeProfile.id)"
          >
            <img
              v-if="activeProfile.avatar_uri"
              :src="activeProfile.avatar_uri"
              loading="lazy"
              class="inline aspect-1 rounded-full object-cover"
            />
            <FingerPrintIcon v-else class="h-5 w-5 text-neutral-200 dark:text-neutral-200" />
          </div>
        </PopoverButton>

        <PopoverPanel
          v-slot="{ close }"
          class="absolute right-0 top-12 z-50 mt-3 w-max max-w-sm rounded-lg sm:px-0 lg:max-w-3xl"
        >
          <div class="z-5 w-48 rounded-lg shadow-lg ring-1 ring-black/5 dark:ring-white/20">
            <div class="relative z-50 rounded-lg bg-white p-4 dark:bg-black">
              <div class="grid grid-cols-1 gap-4" @click="close()">
                <!-- Change password, ... -->
                <!--                <NuxtLink-->
                <!--                  key="EditUser"-->
                <!--                  to="/edit_user"-->
                <!--                  active-class="!text-black dark:text-white"-->
                <!--                  class="font-medium text-neutral-700 dark:text-neutral-200 hover:text-black dark:text-white pt-2"-->
                <!--                  >{{ currUserAuth.attributes.email }}-->
                <!--                </NuxtLink>-->

                <NuxtLink
                  to="/user/profiles"
                  active-class="text-black dark:text-white"
                  class="font-medium text-neutral-700 hover:text-black dark:text-neutral-200"
                  >{{ $t('header.myProfiles') }}
                </NuxtLink>

                <div
                  class="font-medium text-neutral-700 hover:cursor-pointer hover:text-black dark:text-neutral-200"
                  @click="signOut()"
                >
                  {{ $t('header.logout') }}
                </div>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </Popover>
    </div>
    <NavbarConnectWalletModal v-model="showConnectWalletModal" />
  </div>
</template>
